import React from 'react';
import './Portfolio.css';
import PortfolioTexts from './PortfolioTexts';
import PortfolioCard from './PortfolioCard';
class Portfolio extends React.Component{
	constructor(props){
		super(props);
		this.state = {show:false,content:[]};
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
	}
	render(){
		var lang = this.props.lang;
		var content = this.state.content;
		return (<div className={"Portfolio"+(this.state.show?' active':'')} onClick={()=>this.show()}>
				<div className="Portfolio-inner">
					<h1 className="Portfolio-title">{PortfolioTexts[lang].Title} <span onClick={()=>this.hide()}>&times;</span></h1>
					<div className="Portfolio-wrapper">
						{content.map(function(data,key){
							return (<PortfolioCard lang={lang} data={data} key={key}/>);
						})}
					</div>
				</div>
			</div>);
	}
	show(){
		if(!this.state.show)
			this.setState({show:true});
	}
	hide(){
		if(this.state.show)
			this.setState({show:false});
	}
	componentDidMount(){
		var http = new XMLHttpRequest();
		http.open('GET','http:/omarmonterrey.com/portfolio.json', true);
		http.send();
		http.onreadystatechange = function(){
			if(http.readyState === 4 && http.status === 200){
				let data = JSON.parse(http.responseText);
				this.setState({content:data});
			}
		}.bind(this);
	}
}
export default Portfolio;