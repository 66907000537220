import React from 'react';
import logo from './assets/Logo.png';
import './Initial.css';
import Texts from './Texts';
import Portfolio from './Portfolio';
class Initial extends React.Component{
	constructor(props){
		super(props);
		this.state = {activeText:'About me',activeLanguage:'EN'};
		this.changeActive = this.changeActive.bind(this);
	}
	render(){
		var active = this.state.activeText;
		var lang = this.state.activeLanguage;
		return (
			<div className="Initial">
				<div className="languages">
					<span onClick={()=>{this.changeLang('EN')}} className={'link '+(lang==='EN' ? 'active' : '')}>English</span>
					<span onClick={()=>{this.changeLang('ES')}} className={'link '+(lang==='ES' ? 'active' : '')}>Español</span>
				</div>
				<header className="Initial-header">
					<img src={logo} className="Initial-logo" alt="logo" />
					<div className="Initial-info">
						<h2 className="Initial-title">
							{Object.keys(Texts[lang]).map(function(v,k){
								return (<span onClick={()=>this.changeActive(v)} key={k} className={'link '+(v===active?'active':'')}>{v}</span>);
							}.bind(this))}
							
						</h2>
						<p className="Initial-text">{Texts[lang][active]}</p>
					</div>
				</header>
				<Portfolio lang={lang}/>
			</div>
		);
	}
	changeActive(v){
		if(Texts[this.state.activeLanguage][v])
			this.setState({activeText:v});
	}
	changeLang(v){
		if(Texts[v]){
			var i = 0;
			var currentText = this.state.activeText;
			Object.keys(Texts[this.state.activeLanguage]).forEach(function(v,k){
				if(currentText===v)
					i = k;
			});
			this.setState({activeText:Object.keys(Texts[v])[i],activeLanguage:v});
		}
	}
}

export default Initial;