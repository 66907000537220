import React from 'react';
class PortfolioCard extends React.Component{
	constructor(props){
		super(props);
		this.action = this.action.bind(this);
	}
	render(){
		let lang = this.props.lang;
		return (<div className="Portfolio-card" onClick={this.action}>
				<img alt="" src={this.props.data.Image}/>
				<div className="card-meta">
					<h3>{this.props.data.Title[lang]}</h3>
					<p><span>{lang==='EN'?'Role':'Puesto'}:</span> {this.props.data.Role[lang]}</p>
					<p><span>{lang==='EN'?'Date':'Fecha'}:</span> {this.props.data.Date[lang]}</p>
					<p><span>{lang==='EN'?'Technologies':'Tecnologías'}:</span> {this.props.data.Tech}</p>
				</div>
			</div>);
	}
	action(){
		let link = this.props.data.Link;
		if(this.isUrl(link))
			window.open(link,'_BLANK');
		else
			alert(link);
	}
	isUrl(str){
	var pattern = new RegExp('^(https?:\\/\\/)?'+ / protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ / domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ / OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ / port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ / query string
		'(\\#[-a-z\\d_]*)?$','i'); / fragment locator
	return !!pattern.test(str);
	}
}
export default PortfolioCard;