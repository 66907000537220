export default {
	"EN":{
		"About me":"I'm a young Venezuelan programmer with years of experience on web development, fan of solving problems and completely autodidact. There is no degree that speaks for my work, my work speaks for itself.",
		"Skills":"I'm proficient with PHP, JavaScript HTML and CSS. I love programming to solve problems or create complex algorithms, also I'm specially good when it comes to task automation.",
		"Hobbies":"On my free time I love to automate common tasks or play video games if I feel overwhelmed by programming. Also I like to create functions, snippets or helpers to save time on future projects. I like to answer questions on forums and post tips on social media as well.",
		"Weakness":"Mi main weakness is the low skill that I have on graphic design, I'm good materializing previously created designs and making them work, but not designing by myself. Additionally I got distracted easily, thus I concentrate a lot when working and for sure I wouldn't answer messages."
	},
	"ES":{
		"Sobre mi":"Soy un joven programador Venezolano con años de experiencia en el mundo del desarrollo web, aficionado a resolver problemas y totalmente autodidacta. No hay título que hable por mi trabajo, mi trabajo habla por si solo.",
		"Habilidades":"Los lenguajes que domino son PHP, JavaScript, HTML Y CSS. Me encanta aplicar la programación para la resolución de problemas dífíciles o creación de algoritmos complejos, además soy especialmente bueno cuando se trata de automatizar tareas.",
		"Hobbies":"En mi tiempo libre me encanta automatizar tareas comunes o jugar videojuegos si me siento abrumado por la programación. Además me gusta crear funciones, fragmentos de código o ayudantes para ahorrar tiempo en futuros proyectos. También me gusta responder preguntas en foros o publicar tips en redes sociales.",
		"Debilidades":"Mi principal debilidad es la poca habilidad en cuanto a diseño gráfico, soy bueno materializando diseños creados y haciéndolos funcionar, pero no diseñando por mí mismo. Además me distraigo fácilmente, por lo que al trabajar me suelo concentrar mucho y seguramente no conteste mensajes."
	}
}